<template>
  <div>
    <div
      :class="`
        box_seat_class fit-content overflow-hidden my-25
        ${isRight ? 'border-seat-right ' : 'border-seat-left'}
      `"
    >
      <b-button
        :id="`button-ticket-${seat.nameId}`"
        :variant="resolveVariantSeat(seat)"
        :class="`seat_class text-white text-center rounded-lg fw-700 w-100`"
        @click="!seat.notExist && handleClickSeat(seat)"
      >
        {{ seat.nameId }}

        <b-tooltip
          :target="`button-ticket-${seat.nameId}`"
          triggers="hover"
          placement="top"
          boundary="window"
          :variant="resolveVariantSeat(seat)"
        >
          <div class="font-medium-1 text-white fw-700">
            <template v-if="isAvailableSeat(seat)">
              <div class="mb-50">
                Chỗ trống
              </div>
              <div>Giá: {{ formatCurrency(seat.totalPrice * 1000) }} VND</div>
            </template>
            <template v-else-if="resolveVariantSeat(seat) === 'warning'">
              <div>
                Chỗ đã chọn
              </div>
              <div>Giá: {{ formatCurrency(seat.totalPrice * 1000) }} VND</div>
            </template>
            <template v-else>
              Chỗ đã bán
            </template>
          </div>
        </b-tooltip>
      </b-button>
    </div>
  </div>
</template>

<!-- BED -->
<!--
  case 1:
    return 'Đã bán';
  case 2:
    return 'Đang giao dịch (đang bị lock bởi người khác)';
  case 3:
    return 'Chỗ trống có thể bán (bán đúng chặng, hoặc cut để bán)';
  case 4:
    return 'Không được bán (lock cho trưởng tàu)';
  case 5:
    return 'Chặng dài hơn - không được phép bán';
-->
<script>
// import { ref } from '@vue/composition-api'
import { BButton, BTooltip } from 'bootstrap-vue'

import { formatCurrency } from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BButton, BTooltip,
  },
  props: {
    seat: {
      type: Object,
      required: true,
    },
    isRight: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      handleClickSeat,
      isAvailableSeat,
      resolveVariantSeat,
    } = useTrainHandle()

    return {
      formatCurrency,
      handleClickSeat,
      isAvailableSeat,
      resolveVariantSeat,
    }
  },
}
</script>

<style lang="scss" scoped>
.box_seat_class {
  // border-radius: 4px;
  min-width: 40px;

  border-bottom: 3px solid #273959;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.seat_class {
  background-color: #cacaca;
  padding: 8px 4px;

}
.border-seat-right {
  border-right: 6px solid #273959 !important;
  margin-left: 0.7rem;
  margin-right: 0.1rem;
}
.border-seat-left {
  border-left: 6px solid #273959 !important;
  margin-left: 0.1rem;
  margin-right: 0.7rem;
}
</style>
